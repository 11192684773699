import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValuePair } from '@core/models/key-value';
import { MandantClient } from '@shared/models/mandantenClient';
import { Observable } from 'rxjs';
import { ConfigAssetLoaderService } from './config-asset-loader.service';

/**
 * Service für die Komunikation mit den Schnittstellen des Mandanten Microservice.
 */
@Injectable({
    providedIn: 'root'
})
export class MandantenService {
    constructor(private http: HttpClient, private configService: ConfigAssetLoaderService) {}

    /**
     * Aufruf um alle Daten eines angemeldeten Benutzers aus dem MandantenService zu laden.
     */
    loadUiIninitialisierung(): Observable<MandantClient.AdminInitialisierung> {
        const url = `${this.configService.getConfig().k5Mandanten.mandantenServiceUiInitializationUrl}`;

        return this.http.get<MandantClient.AdminInitialisierung>(url);
    }

    /**
     * Aufruf um die Benutzereinstellungen ohne einen Mandantenbezug zu ändern.
     * z.B.: Startmandant
     * @param benutzerId Technische BenutzerId
     * @param einstellung KeyValuePair
     */
    changeBenutzerEinstellungOhneMandant(benutzerId: string, einstellung: KeyValuePair): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${
            this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
        }/benutzer/einstellung?benutzerId=${benutzerId}`;

        return this.http.put(url, JSON.stringify(einstellung), { headers });
    }

    /**
     * Aufruf um die Benutzereinstellungen mit einem Mandantenbezug zu ändern.
     * @param benutzerId Technische BenutzerId
     * @param mandantId Technische MandantId
     * @param einstellung Einstellung als KeyValuePair
     */
    changeBenutzerEinstellungenMitMandant(
        benutzerId: string,
        mandantId: string,
        einstellung: KeyValuePair
    ): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${
            this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
        }/benutzer/einstellung?benutzerId=${benutzerId}&mandantId=${mandantId}`;

        return this.http.put(url, JSON.stringify(einstellung), { headers });
    }

    /**
     * Aufruf zum Löschen einer Benutzereinstellung über den Namen der Einstellung.
     * Über die MandantId wird der Mandantenbezug hergestellt,
     * @param benutzerId Technische BenutzerId
     * @param mandantId Technische MandantId
     * @param einstellungName Name der Einstellung
     */
    deleteBenutzerEinstellung(benutzerId: string, mandantId: string, einstellungName: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${
            this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl
        }/benutzer/einstellung?benutzerId=${benutzerId}&mandantId=${mandantId}&einstellungName=${einstellungName}`;

        return this.http.delete(url, { headers });
    }

    /**
     * Gibt die Liste der Benutzereinstellungen zurück
     * @param benutzerId benutzerId
     * @param mandantId mandant
     * @returns Observable<KeyValuePair>
     */
    getBenutzerEinstellungListe(benutzerId: string, mandantId: string): Observable<KeyValuePair> {
        let params: HttpParams = new HttpParams();
        params = params.append('benutzerId', benutzerId);
        params = params.append('mandantId', mandantId);
        const url = `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl}/benutzer/einstellung/liste`;

        return this.http.get<KeyValuePair>(url, { params: params });
    }

    /**
     * Liefert den Access Token für K5NextHilfe
     * @returns den Token als String
     */
    getK5NextHilfeToken(): Observable<string> {
        return this.http.get<string>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl}/Mandant/K5NextHilfe`
        );
    }

    /**
     * Liefert die Benutzereinstellungen
     * @param benutzerId benutzerId
     * @param mandantId mandantId
     * @returns Benutzereinstellungen
     */
    getBenutzerEinstellungen(benutzerId: string, mandantId: string): Observable<KeyValuePair> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('benutzerId', benutzerId);
        queryParams = queryParams.append('mandantId', mandantId);

        return this.http.get<KeyValuePair>(
            `${this.configService.getConfig().k5Mandanten.mandantenServiceBaseUrl}/Benutzer/Einstellung/Liste`, { params: queryParams }
        );
    }
}
