// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { UiConstants } from '@core/constants/ui-constants';
import { RouterLinkConstants } from '@core/constants/url-constants';
import { MandantenService } from '@core/mandanten.service';
import { KeyValuePair } from '@core/models/key-value';
import { NavItem } from '@core/models/nav-item';
import { MandantClient } from '@shared/models/mandantenClient';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ConfigAssetLoaderService } from './config-asset-loader.service';
import { NavigationService } from './navigation.service';

export enum State {
    Initial,
    Loading,
    Finished,
    Error
}

@Injectable({
    providedIn: 'root'
})
export class UserInformationService {
    private uiInitSubject = new BehaviorSubject<MandantClient.AdminInitialisierung | null>(null);
    public uiInitState$ = this.uiInitSubject.asObservable();
    private userRechteSubject = new BehaviorSubject<string[] | null>(null);
    public userRechte$ = this.userRechteSubject.asObservable();

    // Observables um in anderen Klassen direkt auf die gewünschten Informationen zugreifen zu können
    benutzerInfo$: Observable<MandantClient.Benutzer> = this.uiInitState$.pipe(
        map((uiInitstate: MandantClient.AdminInitialisierung) => uiInitstate?.benutzer),
        distinctUntilChanged()
    );
    mandantInfo$: Observable<MandantClient.InitialisierungMandantData> = this.uiInitState$.pipe(
        map((uiInitstate: MandantClient.AdminInitialisierung) => uiInitstate?.mandant),
        distinctUntilChanged()
    );
    mandantDarstellung$: Observable<MandantClient.DarstellungData> = this.uiInitState$.pipe(
        map((uiInitstate: MandantClient.AdminInitialisierung) => uiInitstate?.mandant.darstellung),
        distinctUntilChanged()
    );
    benutzerEinstellungen$: Observable<KeyValuePair> = this.uiInitState$.pipe(
        map((uiInitstate: MandantClient.AdminInitialisierung) => uiInitstate?.benutzer.einstellungen),
        distinctUntilChanged()
    );
    mandantenEinstellungen$: Observable<KeyValuePair> = this.uiInitState$.pipe(
        map((uiInitstate: MandantClient.AdminInitialisierung) => uiInitstate?.mandant.einstellungen),
        distinctUntilChanged()
    );

    /**
     * Liefert true zurück, wenn sowohl die UI-Initialisierung
     * und auch die Benutzerrechte geladen wurden.
     */
    public userInformationLoaded$: Observable<boolean> = combineLatest([this.uiInitState$, this.userRechte$]).pipe(
        map((values) => values.every((b) => b))
    );

    constructor(
        private mandantenService: MandantenService,
        private navigationService: NavigationService,
        private configAssetLoader: ConfigAssetLoaderService
    ) {
        const uiInfo: MandantClient.AdminInitialisierung = this.getUiInformationFromStorage();
        const uiRechte: string[] = this.getUserRightsFromStorage();
        if (uiInfo) {
            this.updateUiInitialisation(uiInfo);
        }

        if (uiRechte) {
            this.updateUserRights(uiRechte);
        }
    }

    /**
     * Gibt den Namen eines Mandanten zurück
     */
    getMandantName(): string {
        if (this.uiInitSubject?.value) {
            return this.uiInitSubject.value.mandant?.name;
        }
        return '';
    }

    /**
     * Update des Observables mit den aktuellen UI Informationen und
     * Aufruf der lokalen Sicherung der Daten im Session Storage.
     * @param uiInfo Aktuelle Informationen für das UI
     */
    updateUiInitialisation(uiInfo: MandantClient.AdminInitialisierung): void {
        this.uiInitSubject.next(uiInfo);
        this.saveUiInformationToStorage(uiInfo);
    }

    /**
     * Aktualisiert die Darstellung eines Mandanten
     * @param mandantId Id des Mandanten
     * @param mandantDarstellung Neue Darstellung des Mandanten
     *
     */
    updateUiMandantDarstellung(
        mandantId: string,
        mandantDarstellung: MandantClient.UIInitialisierungMandantDarstellung
    ): void {
        const uiInfo = this.uiInitSubject.value;

        // Darstellung des Mandanten aktualisieren
        if (mandantId === this.getMandantId()) {
            uiInfo.mandant.darstellung = mandantDarstellung;
        }
        this.uiInitSubject.next(uiInfo);
    }

    /**
     * Lädt und aktualisiert die Benutzer- und Mandanteninformationen aus dem MandantenService.
     * Lädt und aktualisiert die Rechte des Benutzers aus dem Mandantenservice
     * Wird die Id eines Mandanten als Parameter angegeben wird dieser im Header des Http-Request
     * mitgesendet und ein Mandantenwechsel durchgeführt.
     * @param mandantId Id des ausgewählten Mandanten
     */
    refreshUserAndUiInformation(): Observable<string> {
        const apiCall: Observable<MandantClient.AdminInitialisierung> = this.mandantenService.loadUiIninitialisierung();

        return apiCall.pipe(
            switchMap((response: MandantClient.AdminInitialisierung) => {
                this.updateUiInitialisation(response);
                this.updateUserRights(response.rechte.rechtKeys);

                // Untermenüpunkte für die Rollen Menüpunkt
                const dlEigeneRollen = new NavItem(
                    UiConstants.EIGENE_ROLLEN_LABEL,
                    UiConstants.EIGENE_ROLLEN_ICON,
                    [UiConstants.ROLLE_MODIFY, UiConstants.ROLLE_DELETE],
                    RouterLinkConstants.EIGENE_ROLLEN,
                    null,
                    [],
                    2,
                    UiConstants.POSITION_CENTER,
                    false,
                    UiConstants.EIGENE_ROLLEN_ID
                );

                const dlrollen = new NavItem(
                    UiConstants.DL_ROLLEN_LABEL,
                    UiConstants.DL_ROLLEN_ICON,
                    [UiConstants.ROLLE_MODIFY, UiConstants.ROLLE_DELETE],
                    RouterLinkConstants.DL_ROLLEN,
                    null,
                    [],
                    2,
                    UiConstants.POSITION_CENTER,
                    false,
                    UiConstants.DL_ROLLEN_ID
                );

                if (this.isDienstleister()) {
                    const navitems = this.navigationService.navItems;
                    const rollenIndex = navitems.findIndex((item) => item.routerLink === RouterLinkConstants.ROLLEN);
                    navitems[rollenIndex].exactUrlActiveMatching = true;
                    this.navigationService.setNavItems(navitems);
                    this.navigationService.replaceNavItemsOfParent(RouterLinkConstants.ROLLEN, [
                        dlrollen,
                        dlEigeneRollen
                    ]);
                }

                return of('Erfolgreich initialisiert');
            })
        );
    }

    /**
     * Prüft, ob der Stammmandant des Benutzers ein Dienstleister ist
     * @returns true oder false
     */
    isDienstleister(): boolean {
        return this.uiInitSubject.value?.mandant?.typ === MandantClient.MandantTyp.Dienstleister;
    }

    /**
     * Prüft, ob ein Feature Flag aktiv ist
     * @param featureFlagName Name des Feature flags
     * @returns true oder false
     */
    hasFeatureFlag(featureFlagName: string): boolean {
        if (!featureFlagName || !this.uiInitSubject.value?.featureFlags) {
            return false;
        }
        return this.uiInitSubject.value.featureFlags[featureFlagName] ?? false;
    }

    /**
     * Sichert die aktuellen UI Informationen im Session Storage
     * @param uiInit UiInitialiserung
     */
    saveUiInformationToStorage(uiInit: MandantClient.AdminInitialisierung) {
        sessionStorage.setItem(UiConstants.UIINFO, JSON.stringify(uiInit));
    }

    /**
     * Lädt die UI Informationen aus dem Session Storage und wandelt diese in ein
     * UiInitialisierung-Objekt um.
     */
    getUiInformationFromStorage(): MandantClient.AdminInitialisierung {
        return JSON.parse(sessionStorage.getItem(UiConstants.UIINFO));
    }

    /**
     * Liefert Vorschau Aktiv zurück
     * @returns true oder false
     */
    getVorschauAktiv(): boolean {
        return this.uiInitSubject.value?.mandant.vorschauAktiv
            ? this.uiInitSubject.value?.mandant.vorschauAktiv
            : false;
    }

    /**
     * Aktualisiert die Benutzerrechte
     * @param userRechte Liste der Benutzerrechte
     */
    updateUserRights(userRechte: string[]): void {
        this.userRechteSubject.next(userRechte);
        this.saveUserRightsToStorage(userRechte);
    }

    /**
     * Sichert die UI Rechte des Benutzers im SessionStorage
     * @param userRechte Rechte im UI
     */
    saveUserRightsToStorage(userRechte: string[]): void {
        sessionStorage.setItem(UiConstants.UIRECHTE, JSON.stringify(userRechte));
    }

    /**
     * Lädt die UI Rechte des Benutzers aus dem SessionStorage
     */
    getUserRightsFromStorage(): string[] {
        return JSON.parse(sessionStorage.getItem(UiConstants.UIRECHTE));
    }

    /**
     * Prüft ob der User eines der benötigten Rechte besitzt
     * @param permissions Benötigte Rechte
     * @returns true oder false
     */
    hasPermissions(permissions: string[]): boolean {
        if (this.userRechteSubject.value) {
            let allowedToAccess: boolean = false;
            if (!permissions || permissions[0] === undefined) {
                allowedToAccess = true;
                return allowedToAccess;
            }
            for (const permission of permissions) {
                if (this.userRechteSubject.value.includes(permission)) {
                    allowedToAccess = true;
                }
            }
            return allowedToAccess;
        }
        return false;
    }

    /**
     * Liefert die technische BenutzerId oder leeren String zurück
     */
    getBenutzerId(): string {
        return this.uiInitSubject.value?.benutzer ? this.uiInitSubject.value.benutzer.id : '';
    }

    /**
     * Liefert die SubjectId des Benutzers oder leeren String zurück
     */
    getSubjectId(): string {
        return this.uiInitSubject.value?.benutzer ? this.uiInitSubject.value.benutzer.subjectId : '';
    }

    /**
     * Liefert die technische MandantenId oder leeren String zurück
     */
    getMandantId(): string {
        return this.uiInitSubject.value?.mandant ? this.uiInitSubject.value?.mandant.id : '';
    }

    /**
     * Liefert die technische StartMandantenId oder leeren String zurück
     */
    getStartMandantId(): string {
        return this.getBenutzerEinstellungen() ? this.getBenutzerEinstellungen()['start-mandant'] : '';
    }

    /**
     * Liefert den Benutzernamen oder einen Standardbenutzer zurück
     */
    getUserName(): string {
        return this.uiInitSubject.value?.benutzer?.vorname
            ? this.uiInitSubject.value.benutzer.familienname + ' ' + this.uiInitSubject.value.benutzer.vorname
            : 'Benutzer';
    }

    /**
     * Liefert die Benutzereinstellungen als KeyValuePairs oder null zurück
     */
    getBenutzerEinstellungen(): KeyValuePair {
        return this.uiInitSubject.value?.benutzer?.einstellungen
            ? this.uiInitSubject.value.benutzer?.einstellungen
            : null;
    }

    /**
     * Gibt eine Benutzereinstellung zurück
     * @param key Schlüssel der Einstellung
     * @returns Objekt in den Benutzereinstellungen
     */
    getBenutzerEinstellung<T>(key: string): T | null {
        const value = this.getValueWithKey(this.getBenutzerEinstellungen(), key);

        return value ? JSON.parse(value) : null;
    }

    /**
     * Speichert eine Einstellung als JSON in den Benutzereinstellungen
     * @param key Schlüssel der Einstellungen
     * @param value Wert der Einstellung
     */
    saveBenutzereinstellung<T>(key: string, value: T): Observable<unknown> {
        const einstellung = {
            [key]: JSON.stringify(value)
        };

        return this.changeBenutzerEinstellungen(key, einstellung);
    }

    /**
     * Aufruf der Schnittstelle im MandantenService für das Hinzufügen oder Ändern von Benutzereinstellungen
     * und aktualisieren der Daten im UI.
     * @param einstellung Neue oder veränderte Benutzereinstellung
     * @param key Schlüssel der Benutzereinstellung
     */
    private changeBenutzerEinstellungen(key: string, einstellung: KeyValuePair): Observable<unknown> {
        const benutzerId = this.getBenutzerId();
        const mandantId = this.getMandantId();

        return this.mandantenService.changeBenutzerEinstellungenMitMandant(benutzerId, mandantId, einstellung).pipe(
            tap(() => {
                const uiInitialisierung = this.uiInitSubject.value;
                uiInitialisierung.benutzer.einstellungen[key] = einstellung[key];
                this.saveUiInformationToStorage(uiInitialisierung);
                this.uiInitSubject.next(uiInitialisierung);
            })
        );
    }

    /**
     * Liefert die Mandanteneinstellungen als KeyValuePairs oder null zurück
     */
    getMandantenEinstellungen(): KeyValuePair {
        return this.uiInitSubject.getValue()?.mandant?.einstellungen
            ? this.uiInitSubject.getValue().mandant?.einstellungen
            : null;
    }

    /**
     * Liefert entweder das vom Mandanten oder Benutzer voreingestellte Farbschema für das UI zurück.
     */
    getUiThemeName(): string {
        const benutzerEinstellungen = this.getBenutzerEinstellungen();
        const mandatenEinstellungen = this.getMandantenEinstellungen();
        let theme = '';
        if (mandatenEinstellungen) {
            const value = this.getValueWithKey(mandatenEinstellungen, UiConstants.THEME);
            theme = value || '';
        }
        // Benutzereinstellungen überschreiben Mandanteneinstellungen
        if (benutzerEinstellungen) {
            const value = this.getValueWithKey(benutzerEinstellungen, UiConstants.THEME);
            theme = value || theme;
        }
        return theme;
    }

    /**
     * Liefert den Typ des Stammmandanten zurück.
     * @returns Typ des Stammmandanten
     */
    getTypDesStammandanten(): MandantClient.MandantTyp {
        return this.uiInitSubject.getValue()?.mandant?.typ;
    }

    /**
     * Stellt fest, ob der Stammmandant des Benutzers Gemeinde ist.
     * @returns true oder false
     */
    isBenutzerOfGemeinde(): boolean {
        return this.getTypDesStammandanten() === MandantClient.MandantTyp.Gemeinde;
    }

    /**
     * Stellt fest, ob der Stammmandant des Benutzers Dienstleister ist.
     * @returns true oder false
     */
    isBenutzerOfDienstleister(): boolean {
        return this.getTypDesStammandanten() === MandantClient.MandantTyp.Dienstleister;
    }

    /**
     * Liefert aus den Benutzer- oder Mandanteneinstellungen den gesuchten Wert
     * mit Hilfe des Schlüsseleintrages oder null zurück.
     * @param einstellungen Benutzer- oder Mandanteneinstellungen
     * @param key Schlüsseleintrag
     */
    getValueWithKey(einstellungen: KeyValuePair, key: string): string | null {
        let value: string | null = null;

        if (einstellungen) {
            value = einstellungen[key];
        }
        return value ?? null;
    }

    /**
     * Liefert true, wenn die Benutzerinformationen geladen sind.
     */
    public hasUserInfo(): boolean {
        if (this.userRechteSubject.value && this.uiInitSubject.value) {
            return true;
        }
        return false;
    }
}
